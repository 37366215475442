import React, { useEffect, useState } from "react";
import useUserData from "../../../../../hooks/useUserData";
import { IconButton, Menu, MenuProps, styled, Tooltip } from "@mui/material";
import { useAccount, useBalance } from "wagmi";
import { getUserCDOBalance } from "../../../../../queries/integrations/getCdoBalance.query";
import { useMutation } from 'react-query';
import useContracts from "@/hooks/contract/useContracts";
import { getCDOPrice } from "../../../../../v2/components/organisms/swapbox-erc20/functions/getCDOPrice";
import { Button } from "@/components/atoms";
import { useRouter } from "next/router";
import { checkBonusInfo, BonusResponse } from "@/queries/users/bonus-info.query";
import { ErrorDto } from "@/types/error.dto";
import { CDO_ADDRESS } from "../../../../../constants/blockchain";
import { useSelector } from "react-redux";
import { selectSelectedChainId } from "../../../../../Redux/slices/config.slice";

interface Props {
    isMobile?: boolean
}

const CdoBalance = ({ isMobile = false }: Props) => {
    const getUser = useUserData();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { address } = useAccount();
    const chainId = useSelector(selectSelectedChainId) as number;
    const menuOpen = Boolean(anchorEl);
    const [cdo, setCdo] = useState<number | null>(null);
    const [scdo, setScdo] = useState(0);
    const [lockedScdo, setLockedScdo] = useState(0);
    const [quarterLockedScdo, setQuarterLockedScdo] = useState(0);
    const [tcdo, setTcdo] = useState(0);
    const [claimedBonus, setClaimedBonus] = useState(0);
    const [sum, setSum] = useState<number | null>(null);
    const [hasCDOCost, setCDOCost] = useState<BigInt>(BigInt(0));
    const contracts = useContracts();
    const contract = contracts.cdoContract;
    const scaledValue: number = Number(hasCDOCost) / 10 ** 14;
    const router = useRouter();

    const balanceCDO = useBalance({
        address,
        token: CDO_ADDRESS,
        chainId,
    });

    useEffect(() => {
        balanceCDO.data?.formatted ? setCdo(parseFloat(balanceCDO.data?.formatted)) : setCdo(null);
    }, [balanceCDO])

    const checkDecimalPlaces = (number: number) => {
        const numAsString = number.toString();
        const decimalIndex = numAsString.indexOf('.');
        if(decimalIndex === -1) return false;
        return numAsString.slice(decimalIndex + 1).length > 3;
    }

    const cdoBalanceMut = useMutation(getUserCDOBalance, {
        retry: false,
        onSuccess: (data) => {
            // console.log(data);
            setCdo(parseInt(data.balance) / 10 ** 18);
        },
        onError: (e) => {
            setCdo(null);
            setSum(null);
            console.error(e);
        },
    });

    const bonusInfo = useMutation(checkBonusInfo, {
        retry: false,
        onSuccess: (data) => {
          // console.log(data)
          if(data.error) {
            console.error(data.error);
            setClaimedBonus(0);
          } else {
            setClaimedBonus(data.claimedBonusCDO);
          }
        },
        onError: (e: ErrorDto) => {
          console.log(e);
        },
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const formatNumber = (num: number, precision = 2) => {
        const map = [
            { suffix: 'Sp', threshold: 1e24 },
            { suffix: 'S', threshold: 1e21 },
            { suffix: 'Qi', threshold: 1e18 },
            { suffix: 'Q', threshold: 1e15 },
            { suffix: 'T', threshold: 1e12 },
            { suffix: 'B', threshold: 1e9 },
            { suffix: 'M', threshold: 1e6 },
            { suffix: 'K', threshold: 1e3 },
            { suffix: '', threshold: 1 },
        ];
        const found = map.find((x) => Math.abs(num) >= x.threshold);
        if (found) {
            const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
            return formatted;
        } else {
            return num.toFixed(2);
        }
    };

    useEffect(() => {
        let scdo = 0;
        let lockedScdo = 0;
        let quarterLockedScdo = 0;
        let tcdo = 0;
        let sum = 0;
        if (getUser && address) {
            const wallet = getUser.wallets.find(wallet => wallet.address.toLowerCase() === address.toLowerCase());
            if (wallet) {
                scdo += parseFloat(wallet.scdo);
                lockedScdo += parseFloat(wallet.lockedScdo);
                quarterLockedScdo += wallet.lockedQuareterScdo ? parseFloat(wallet.lockedQuareterScdo) : 0;
                tcdo += parseFloat(wallet.tcdo);
            }
        }
        if(cdo || cdo === 0){
            sum += cdo + scdo + tcdo;
        }
        setScdo(scdo);
        setLockedScdo(lockedScdo);
        setQuarterLockedScdo(quarterLockedScdo);
        setTcdo(tcdo);
        setSum(sum);
    }, [cdo]);

    useEffect(() => {
        // cdoBalanceMut.mutate();
        bonusInfo.mutate();
        isMobile && getCDOPrice(contract).then((e: BigInt) => setCDOCost(e));
    }, []);

    if(isMobile){
        return (
            <div className="flex flex-col border rounded-xl p-4 m-2 ml-4">
                <span className="text-[12px] text-gray-700 font-medium">CDO BALANCE  </span>                        
                { sum === null ? 
                    <>
                        <Tooltip title="Blockchain communication fail.">
                            <span className="text-gray-900 text-[18px] font-medium">
                                N/A&nbsp;CDO
                            </span>
                        </Tooltip>
                    </> :
                    <>
                        <span className="text-gray-900 text-[18px] font-medium">
                            {formatNumber(sum)}&nbsp;CDO<span style={{ color: "#5850EC" }}>*</span>
                        </span>
                    </>
                }
                <div className="py-1 flex flex-row text-[14px]">
                    <span style={{ color: "#111827", fontWeight: "500" }}>
                        {cdo ? formatNumber(cdo) : "N/A"}
                    </span>
                    <span style={{ color: "#474D66" }}>
                        &nbsp;CDO
                    </span>
                </div>
                <div className="py-1 flex flex-row text-[14px]">
                    <span style={{ color: "#111827", fontWeight: "500" }}>
                        {formatNumber(scdo)}
                    </span>
                    <span style={{ color: "#474D66" }}>
                        &nbsp;sCDO
                    </span>
                </div>
                { tcdo !== 0 && <div className="py-1 flex flex-row text-[14px]">
                    <span style={{ color: "#111827", fontWeight: "500" }}>
                        {formatNumber(tcdo)}
                    </span>
                    <span style={{ color: "#474D66" }}>
                        &nbsp;tCDO
                    </span>
                </div>}
                { claimedBonus !== 0 && <div className="py-1 flex flex-row text-[14px]">
                    <span style={{ color: "#111827", fontWeight: "500" }}>
                        {formatNumber(claimedBonus)}
                    </span>
                    <span style={{ color: "#474D66" }}>
                        &nbsp;Claimed bonus CDO
                    </span>
                </div>}
                {(quarterLockedScdo !== 0 || lockedScdo !== 0) && <div className="py-1 flex flex-row text-[14px]">
                    <span style={{ color: "#111827", fontWeight: "500" }}>
                        {formatNumber(lockedScdo)}
                    </span>
                    <span style={{ color: "#474D66" }}>
                        &nbsp;sCDO staked in SH
                    </span>
                </div>}
                {quarterLockedScdo !== 0 && <div className="py-1 flex flex-row text-[14px]">
                    <span style={{ color: "#111827", fontWeight: "500" }}>
                        {formatNumber(quarterLockedScdo)}
                    </span>
                    <span style={{ color: "#474D66" }}>
                        &nbsp;Locked sCDO
                    </span>
                </div>}
                <div className="text-[12px] my-1 mb-2 flex flex-row justify-between bg-light2 rounded-lg p-2">
                    <span className="text-gray-600">CDO Price</span>
                    <span className="text-gray-900 font-medium">${scaledValue / 10000} USD</span>
                </div>
                <Button variant="gradient" onClick={() => router.push("/swap")}>Buy</Button>
            </div>
        )
    }
    else return ( 
    <>
        <IconButton
            id="wallet-button"
            aria-controls={menuOpen ? "wallet-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            onClick={handleClick}
            size={"small"}
            sx={{
            background: "#fff !important",
            border: "1px solid #E5E7EB",
            borderRadius: "8px",
            color: "#374151",
            fontSize: "14px",
            padding: 1,
            lineHeight: "16px",
            "&:hover": { background: "#fff !important" },
            }}
        >
            <span style={{ color: "#696F8C" }}>
                Balance:&nbsp;
            </span>
            <span style={{ color: "#474D66", fontWeight: "500" }}>
                { sum !== null ? <>{formatNumber(sum)} </> : <>N/A</>}
                CDO<span style={{ color: "#5850EC" }}>*</span>
            </span>
        </IconButton>
        <StyledMenu
            id="wallet-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleCloseMenu}
            MenuListProps={{
            "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
            }}
            transformOrigin={{
            vertical: "top",
            horizontal: "right",
            }}
        >
            <div className="flex flex-row pb-1 p-2 pl-4 pr-4 gap-4 justify-between" style={{ fontSize: "14px", width: isMobile ? "176px" : "140px"}}>
                <span style={{ color: "#4B5563" }}>Balance</span>
                {/* <Tooltip
                    title="To claim CDO, log in to Candao Wallet mobile application."
                    arrow
                    enterTouchDelay={0}
                    >
                    <svg
                        className={`icon icon-tabler icons-tabler-outline icon-tabler-info-circle`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#E5EDFF"
                        stroke="#5850EC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <linearGradient
                        id="gradient"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                        >
                        <stop offset="0%" stopColor="#5850EC" />
                        <stop offset="50%" stopColor="#9061F9" />
                        <stop offset="100%" stopColor="#FB923C" />
                        </linearGradient>
                        <path
                        stroke="url(#gradient)"
                        d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"
                        />
                        <path d="M12 9h.01" />
                        <path stroke="url(#gradient)" d="M11 12h1v4h1" />
                    </svg>
                </Tooltip> */}
            </div>
            <div className="flex flex-row gap-4 p-2 px-4 justify-between" style={{ fontSize: "14px" }}>
                <span style={{ color: "#474D66" }}>
                    CDO&nbsp;
                </span>
                { cdo === null ? 
                    <>
                        <Tooltip title="Blockchain communication fail.">
                            <span style={{ color: "#111827", fontWeight: "500" }}>
                                N/A
                            </span>
                        </Tooltip>
                    </> :
                    <>
                        <Tooltip title={checkDecimalPlaces(cdo) ? cdo : ""} placement="right" componentsProps={{tooltip: {sx: {bgcolor: 'common.white', border: '1px solid #E5E7EB', color: "#111827", fontSize: "12px" },},}}>
                            <span style={{ color: "#111827", fontWeight: "500" }}>
                                {formatNumber(cdo)}
                            </span>
                        </Tooltip>
                    </>
                }
            </div>
            <div className="flex flex-row gap-4 p-2 px-4 justify-between" style={{ fontSize: "14px" }}>
                <span style={{ color: "#474D66" }}>
                    sCDO&nbsp;
                </span>
                <span style={{ color: "#111827", fontWeight: "500" }}>
                    {formatNumber(scdo)}
                </span>
            </div>
            {tcdo !== 0 && <div className="flex flex-row gap-4 p-2 px-4 justify-between" style={{ fontSize: "14px" }}>
                <span style={{ color: "#474D66" }}>
                    tCDO&nbsp;
                </span>
                <span style={{ color: "#111827", fontWeight: "500" }}>
                    {formatNumber(tcdo)}
                </span>
            </div>}
            {claimedBonus !== 0 && <div className="flex flex-row gap-4 p-2 px-4 justify-between" style={{ fontSize: "14px" }}>
                <span style={{ color: "#474D66" }}>
                    Claimed bonus CDO&nbsp;
                </span>
                <span style={{ color: "#111827", fontWeight: "500" }}>
                    {formatNumber(claimedBonus)}
                </span>
            </div>}
            {(quarterLockedScdo !== 0 || lockedScdo !== 0) && <div className="flex flex-row gap-4 p-2 px-4 justify-between" style={{ fontSize: "14px" }}>
                <span style={{ color: "#474D66" }}>
                    sCDO staked in SH&nbsp;
                </span>
                <span style={{ color: "#111827", fontWeight: "500" }}>
                    {formatNumber(lockedScdo)}
                </span>
            </div>
            }
            {quarterLockedScdo !== 0 && <div className="flex flex-row gap-4 p-2 px-4 justify-between" style={{ fontSize: "14px" }}>
                <span style={{ color: "#474D66" }}>
                    Locked sCDO
                </span>
                <span style={{ color: "#111827", fontWeight: "500" }}>
                    {formatNumber(quarterLockedScdo)}
                </span>
            </div>
            } 
        </StyledMenu>
    </>
  );
};

export default CdoBalance;

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
    ({ theme }) => ({
      "& .MuiPaper-root": {
        marginTop: theme.spacing(1),
        boxShadow:
          "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      },
    }),
  );
  

