import { Box } from "@/components/molecules";
import { Button } from "@/components/atoms";
import Link from "next/link";
import React, { FC, useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import useUserData from "@/hooks/useUserData";
import { BonusesRoundInfo, RoundResponse } from "@/queries/users/bonuses-round-info";
import { useMutation } from "wagmi";
import { ErrorDto } from "@/types/error.dto";
import { LinearProgress, styled } from '@mui/material';
import { intervalToDuration } from "date-fns";

export const EarnCdoStep2 = () => {
    const router = useRouter();
    const userData = useUserData();
    const [roundInfo, setRoundInfo] = useState<RoundResponse>();

    const bonusInfo = useMutation(BonusesRoundInfo, {
        retry: false,
        onSuccess: (data) => {
            // console.log(data)
          if(data.error) {
            console.error(data.error);
          } else {
            setRoundInfo(data);
          }
        },
        onError: (e: ErrorDto) => {
          console.error(e);
        },
    });

    useEffect(() => {
        bonusInfo.mutate();
    }, [])

    const step1Status = userData?.profile.avatarUrl && userData.profile.avatarUrl !== ""
    const step2Status = userData?.profile.socialAccountsData && userData.profile.isSocialAccountsVerified && userData?.profile.roles && userData?.profile.roles.length > 0;

    const timeLeft = roundInfo && intervalToDuration({start: 0, end: roundInfo.secondsLeft * 1000});

    if(step1Status && step2Status) {
        return (
            <Box className="p-4 flex flex-col gap-1">
                <span className="text-[12px] font-semibold uppercase text-indigo-600">step 2</span>
                <span className="text-[16px] font-semibold text-gray-800">Earn CDO Tokens</span>
                <span className="text-[14px] text-gray-600">Share content and earn {roundInfo?.currentPrice} CDO tokens each time a friend joins Candao via your shared posts, verifies their account, and invites a friend to do the same. <button onClick={() => {router.push("/earn-cdo")}} className="text-indigo-600">Learn more</button></span>
                {roundInfo &&
                    <div className="py-2 flex flex-col gap-1">
                        <CustomLinearProgress variant="determinate" value={((roundInfo?.cdoLeft / 250000) * 100)} />
                        <div className="flex w-full justify-between gap-2 text-[12px] text-gray-700">
                            <span>Current round rewards left:</span>
                            <span className="font-medium">{((roundInfo?.cdoLeft / 250000) * 100).toFixed(2)}%</span>
                        </div>
                        <CustomLinearProgress variant="determinate" value={((roundInfo?.secondsLeft / 604800) * 100)} />
                        <div className="flex w-full justify-between gap-2 text-[12px] text-gray-700">
                            <span>{timeLeft?.days} days, {timeLeft?.hours} hours, {timeLeft?.minutes} minutes left.</span>
                        </div>
                        <div className="flex w-full justify-between gap-2 text-[12px] text-gray-700">
                            <span>Next round prize:</span>
                            <span className="font-medium">{roundInfo.nextPrice} CDO</span>
                        </div>
                    </div> 
                }
                <Button className="mt-2 w-full flex flex-row gap-2 items-center" variant="primary" size="base" onClick={() => router.push("/social-graph?step=2")}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.6665 13.3334L13.3332 8.66675M8.6665 13.3334V9.33341C8.6665 9.1566 8.73674 8.98703 8.86177 8.86201C8.98679 8.73699 9.15636 8.66675 9.33317 8.66675H13.3332M8.6665 13.3334H3.99984C3.64622 13.3334 3.30708 13.1929 3.05703 12.9429C2.80698 12.6928 2.6665 12.3537 2.6665 12.0001V4.00008C2.6665 3.64646 2.80698 3.30732 3.05703 3.05727C3.30708 2.80722 3.64622 2.66675 3.99984 2.66675H11.9998C12.3535 2.66675 12.6926 2.80722 12.9426 3.05727C13.1927 3.30732 13.3332 3.64646 13.3332 4.00008V8.66675" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    Share 2 Earn
                </Button>
            </Box>
        );
    }
};

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    borderRadius: '8px',
    height: '10px',
    backgroundColor: theme.palette.grey[200],
    '& .MuiLinearProgress-bar': {
      borderRadius: '8px',
      background: 'linear-gradient(to right, #4F46E5 0%, #9061F9 56%, #FB923C 100%)',
    },
}));
